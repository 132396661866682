import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firebase } from '@firebase/app';
import '@firebase/functions';
import { includes } from 'lodash';
import { app_config } from 'src/environments/app_config';
import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private afs: AngularFirestore, private storage: Storage) { }

  getNationalPhoneNumberFormat(phoneNumber) {
    const removedSpaces = phoneNumber.replace(/\s/g, '');
    const last10Digits = removedSpaces.substr(phoneNumber.length - 10);
    return last10Digits;
  }

  async isValidNumberForRegion(phone) {
    if (!phone) return false;
    console.log('phone countryCode', phone, app_config.countryCode);
    let number;
    try {
      number = await phoneUtil.parse(phone, app_config.countryCode);
      console.info('number', number);
    } catch (error) {
      console.error('error number', error);
      return false;
    }
    const validPhone = phoneUtil.isValidNumberForRegion(number, app_config.countryCode);
    console.log('validPhone', validPhone);
    return validPhone;
  }
  
  formatPhoneNumber(phone) {
    const number = phoneUtil.parse(phone, app_config.countryCode);
    const formatted = phoneUtil.format(number, PNF.E164);
    console.log('formatted', formatted);
    return formatted;
  }

  createBuyerFromFacebook(payload) {
    return firebase.app().functions().httpsCallable('createBuyerFromFacebookCF')(payload);
  }

  createSellerFromFacebook(payload) {
    return firebase.app().functions().httpsCallable('createSellerFromFacebookCF')(payload);
  }

  reloginSellerFromFacebook(payload) {
    return firebase.app().functions().httpsCallable('reloginSellerFromFacebookCF')(payload);
  }

  createDelivererFromFacebook(payload) {
    return firebase.app().functions().httpsCallable('createDelivererFromFacebookCF')(payload);
  }

  createJobberFromFacebook(payload) {
    return firebase.app().functions().httpsCallable('createJobberFromFacebookCF')(payload);
  }

  getClient(payload) {
    return this.afs.collection('clients').doc(payload.client);
  }

  setDelivererBarangay(payload) {
    return firebase.app().functions().httpsCallable('setDelivererBarangayCF')(payload);
  }

  sendMessengerAlert(payload) {
    return firebase.app().functions().httpsCallable('sendMessengerAlertCF')(payload);
  }

  checkAccountByType(payload) {
    const usersRef = this.afs.collection('users');
    return usersRef.doc(payload.uid).get().toPromise().then((userSnapshot) => {
      console.log('userSnapshot', userSnapshot);
      if (!userSnapshot.exists) return false;

      const user = userSnapshot.data();
      console.log('user snapshot', user);
      return includes(user[payload.type], payload.client);
    }).catch((error) => {
      console.log('user snap error', error);
      return false;
    });
  }

  syncCart(payload) {
    return this.afs.collection('clients').doc(payload.client).collection('buyers').doc(payload.uid).update(payload.update);
  }

  setStorage(item, value) {
    return this.storage.set(item, value);
  }

  getStorage(item) {
    return this.storage.get(item).then((val) => {
      if (!val) return null;
      console.log('get storage', val);
      return val;
    }).catch((error) => {
      console.error('error get storage', item, error);
      return null;
    });
  }

  removeStorage(item) {
    return this.storage.remove(item);
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['home']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'featured-stores',
    loadChildren: () => import('./featured-stores/featured-stores.module').then( m => m.FeaturedStoresPageModule)
  },
  {
    path: 'more-stores',
    loadChildren: () => import('./more-stores/more-stores.module').then( m => m.MoreStoresPageModule)
  },
  {
    path: 'premium-stores',
    loadChildren: () => import('./premium-stores/premium-stores.module').then( m => m.PremiumStoresPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./modals/cart/cart.module').then( m => m.CartPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'item-detail',
    loadChildren: () => import('./modals/item-detail/item-detail.module').then( m => m.ItemDetailPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./modals/order-detail/order-detail.module').then( m => m.OrderDetailPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'login',
    loadChildren: () => import('./modals/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'buyer-form',
    loadChildren: () => import('./modals/buyer-form/buyer-form.module').then( m => m.BuyerFormPageModule)
  },
  {
    path: 'hits-filter',
    loadChildren: () => import('./modals/hits-filter/hits-filter.module').then( m => m.HitsFilterPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'orders/:order_id',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'settings',
    loadChildren: () => import('./modals/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'order-update/:order_id/:status',
    loadChildren: () => import('./seller/order-update/order-update.module').then( m => m.OrderUpdatePageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'sales',
    loadChildren: () => import('./seller/sales/sales.module').then( m => m.SalesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'sales/:order_id',
    loadChildren: () => import('./seller/sales/sales.module').then( m => m.SalesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToHome }
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then( m => m.TestPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

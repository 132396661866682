import { Component, HostListener, OnInit } from '@angular/core';

import { MenuController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AutocloseOverlaysService } from './services/autoclose-overlay.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { SettingsPage } from './modals/settings/settings.page';
import { LoginPage } from './modals/login/login.page';
import { ProfileService } from './services/profile.service';
import { map, includes } from 'lodash';
import { UserAccount } from './common/interfaces';
import { app_config } from 'src/environments/app_config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.autocloseOverlaysService.trigger();
  }
  selectedIndex = 0;
  appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Stores',
      url: '/more-stores',
      icon: 'bag-handle'
    }
  ];

  client: string;
  isDesktop: boolean;
  loggedIn: boolean;
  seller: boolean;
  user: UserAccount;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private autocloseOverlaysService: AutocloseOverlaysService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private modalController: ModalController,
    private profileServ: ProfileService,
    private menuController: MenuController
  ) {
    this.initializeApp();
    this.client = app_config.client;
    this.getUser();
    this.checkPlatform();
  }

  checkPlatform(): void {
    this.isDesktop = this.platform.is('desktop');
    console.log('this.isDesktop', this.isDesktop);

    if (this.isDesktop) {
      this.menuController.enable(false, 'rightmenu');
    } else {
      this.menuController.enable(true, 'rightmenu');
    }
  }

  getUser() {
    this.afAuth.user.pipe(untilDestroyed(this)).subscribe(async (user) => {
      this.loggedIn = user ? true : false;
      console.log('user in app', user);
      console.log('this.loggedIn', this.loggedIn);

      if (!user) return;

      this.profileServ.getProfile(user.uid).pipe(untilDestroyed(this)).subscribe((userProfile) => {
        this.user = userProfile;
        if (!this.user) return;
        if (!this.user.seller) return;

        const seller = map(this.user.seller);
        this.seller = includes(seller, this.client);
        console.log('this.seller', this.seller);
      });
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  viewOrders() {
    this.router.navigate(['orders']);
  }

  viewSales() {
    this.router.navigate(['sales']);
  }

  viewProfile() {
    this.router.navigate(['profile']);
  }

  async viewSettings() {
    const modal = await this.modalController.create({
      component: SettingsPage,
      cssClass: 'custom-modal',
    });
    await modal.present();
  }

  async showLogin() {
    const loginModal = await this.modalController.create({
      component: LoginPage,
      cssClass: 'custom-modal'
    });
    await loginModal.present();
    const { data } = await loginModal.onDidDismiss();
    if (!data) return;
    console.log('login form data', data);
    return data;
  }

  async logout() {
    if (this.isDesktop) {
      const menuEnabled = await this.menuController.isEnabled('rightmenu');
      console.log('menuEnabled', menuEnabled);
      if (menuEnabled) {
        this.menuController.enable(false, 'rightmenu');
      } else {
        this.menuController.enable(true, 'rightmenu');
      }
    } else {
      this.menuController.toggle('rightmenu');
    }
    this.afAuth.signOut();
    this.router.navigate(['home']);
  }
}

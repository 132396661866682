import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { app_config } from 'src/environments/app_config';

export const firebaseUiAuthConfig: firebaseui.auth.Config = {
    signInFlow: 'redirect',
    signInOptions: [
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        {
            provider: firebase.default.auth.PhoneAuthProvider.PROVIDER_ID,
            recaptchaParameters: {
                type: 'image', // 'audio'
                size: 'invisible', //'normal' or 'invisible' or 'compact'
                badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
            },
            defaultCountry: app_config.countryCode,
            whitelistedCountries: [app_config.countryCode]
        }
    ],
    tosUrl: 'https://pinoymarketplace.com.au/privacy',
    // Privacy policy url/callback.
    privacyPolicyUrl: 'https://pinoymarketplace.com.au/privacy',
    credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { UserAccount } from 'src/app/common/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public user: Observable<UserAccount>;
  userDoc: AngularFirestoreDocument<UserAccount>;
  usersCol: AngularFirestoreCollection;
  clientsCol: AngularFirestoreCollection;

  constructor(private afs: AngularFirestore) {
    this.usersCol = this.afs.collection('users');
    this.clientsCol = this.afs.collection('clients');
  }

  getProfile(uid) {
    return this.user = this.usersCol.doc<UserAccount>(uid).valueChanges();
  }

  getPerson(uid) {
    return this.usersCol.doc<UserAccount>(uid).valueChanges();
  }

  getBuyerProfile(payload) {
    return this.clientsCol.doc(payload.client).collection('buyers').doc(payload.uid).get();
  }
}

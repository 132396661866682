export const app_config = {
  client: 'pinoy-marketplace',
  currency: 'AUD',
  country: 'Australia',
  countryCode: 'AU',
  app_version: '1.10.10',
  notes: {
    a: 'Initial deploy to new server'
  }
};
  
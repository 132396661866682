import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoCompleteService } from 'ionic4-auto-complete';
import { AUAddress } from 'src/app/common/interfaces';
import { api } from 'src/environments/api';

@Injectable({
  providedIn: 'root'
})
export class AddressesService implements AutoCompleteService {
  labelAttribute = 'name';

  private addresses: Array<AUAddress> = [];

  constructor(private http: HttpClient) { }

  getResults(keyword?: string): Observable<any[]> {
    if (!keyword || keyword.length < 1) {
      return EMPTY;
    }
    keyword = typeof keyword === 'string' ? keyword : '';

    const url = `${api.here_geocode_url}?country=${api.here_country_code}&apiKey=${api.here_api_key}&query=${encodeURIComponent(keyword)}`;

    console.log('url', url);

    let observable: Observable<any>;

    // if (this.addresses.length === 0) {
    console.log('api.here_geocode_url', api);

    observable = this.http.get(url).pipe(
      map(
        (results) => {
          if (results['suggestions']) {
            console.log('this.results', results);
            this.addresses = results['suggestions'];
          }
          console.log('this.addresses', this.addresses);

          return this.addresses;
        }
      )
    );
    // } else {
    //   console.log('with addresses', this.addresses);
    //   observable = of(this.addresses);
    // }

    return observable.pipe(
      map(
        (result) => {
          console.log('result', result);

          if (!result) return [];

          result.forEach(
            (item) => {
              item['name'] = `${item.address.houseNumber ? item.address.houseNumber + ' ' : ''}${item.address.street ? item.address.street + ', ' : ''}${item.address.district ? item.address.district + ', ' : ''}${item.address.state ? item.address.state + ' ' : ''}${item.address.postalCode ? item.address.postalCode : ''}`;
            }
          );
          return result;
        }
      )
    );
  }

  // getItemLabel(item: any) {
  //   console.log('getItemLabel', item);
  //   return `${(item.address.houseNumber + ' ') : ''}${(item.address.street + ', ') : ''}${(item.address.district + ', ') : ''}${(item.address.state + ' ') : ''}${(item.address.postalCode + ' ') : ''}`;
  // }
}

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { trim } from 'lodash';
import { ModalController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { app_config } from 'src/environments/app_config';
import { BuyerFormPage } from 'src/app/modals/buyer-form/buyer-form.page';
import { QueryParams } from 'src/app/common/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare const closeMess: any;

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  @Input() gotoCart: boolean;
  @Input() params: QueryParams;

  isNewUser: boolean;
  providerId: string;
  phoneNumber: string;
  signedUpText: string;
  ui;
  uid: string;

  constructor(private commonServ: CommonService, private route: ActivatedRoute, private authServ: AuthService, private spinner: NgxSpinnerService, private modalController: ModalController) {
    this.getRouteParams();
  }

  getRouteParams() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
      console.log('login params', params);
      if (params && params['u']) {
        const params_split = params.u.split('-_-');
        this.params = {
          user_id: params_split[0],
          client: app_config.client,
          mode: trim(params_split[1]),
          segment: trim(params_split[2]) || ''
        };
        console.log('login this params', this.params);
        this.getCurrentUser();
      } else {
        this.params = {
          client: app_config.client,
          mode: null
        };
        closeMess();
      }
    });
  }

  async getCurrentUser() {
    console.log('getcurrentUser');
    this.authServ.afAuth.authState.pipe(untilDestroyed(this)).subscribe(async (response) => {
      if (response) {
        console.log('Logged in :)');
        const currentUser = await this.authServ.afAuth.currentUser;
        console.log('login currentUser', currentUser);

        if (currentUser) {
          this.uid = currentUser.uid;

          const payload = {
            uid: this.uid,
            user_id: this.params ? this.params.user_id : null
          };
          console.log('facebook payload', payload);

          this.checkUserAccountTypes(payload);
        }
      }
    });
  }

  ngOnInit() {
    if (!window.history.state.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
  }

  async successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    this.spinner.show();
    const authResult = signInSuccessData.authResult;
    // const user = authResult.user;
    this.phoneNumber = authResult.user.phoneNumber;
    // const email = authResult.user.email;
    // const credential = authResult.credential;
    this.uid = authResult.user.uid;
    this.isNewUser = authResult.additionalUserInfo.isNewUser;
    this.providerId = authResult.additionalUserInfo.providerId;
    // const operationType = authResult.operationType;
    this.commonServ.setStorage('phone', this.phoneNumber);
    console.info('providerId', this.providerId);
    console.info('uid', this.uid);
    // console.info('credential', credential);
    console.info('authResult', authResult);
    console.info('phoneNumber', this.phoneNumber);
    console.info('isNewUser', this.isNewUser);
    // const last10DigitSignIn = this.commonServ.getNationalPhoneNumberFormat(phoneNumber);
    // console.log('last10DigitSignIn', last10DigitSignIn);

    this.signedUpText = 'Successful Sign in!';

    const payload = {
      uid: this.uid,
      user_id: this.params ? this.params.user_id : null
    };
    console.log('facebook payload', payload);

    if (this.isNewUser) {
      if (this.params && this.params['mode'] === 'sell') {
        this.createSellerAccount(payload);
      } else if (this.params && this.params['mode'] === 'serve') {
        this.createJobberAccount(payload);
      } else {
        this.modalController.dismiss();
        this.createBuyerAccount(payload);
      }
    } else {
      console.log('login mode', this.params);
      this.spinner.hide();
      this.checkUserAccountTypes(payload);
    }
  }

  checkUserAccountTypes(payload) {
    const accountPayload = {
      client: app_config.client,
      uid: this.uid
    };

    if (this.params && this.params['mode'] === 'sell') {
      accountPayload['type'] = 'seller';
      this.commonServ.checkAccountByType(accountPayload).then((exists) => {
        if (exists) {
          this.reloginSellerAccount(payload);
        } else {
          this.createSellerAccount(payload);
        }
      });
    } else if (this.params && this.params['mode'] === 'serve') {
      accountPayload['type'] = 'jobber';
      this.commonServ.checkAccountByType(accountPayload).then((exists) => {
        if (exists) {
          closeMess();
        } else {
          this.createJobberAccount(payload);
        }
      });
    } else {
      accountPayload['type'] = 'buyer';
      this.commonServ.checkAccountByType(accountPayload).then((exists) => {
        console.log('buyer account exists', exists);
        this.modalController.dismiss({ data: true });
        if (!exists) {
          this.createBuyerAccount(payload);
        }
      });
    }
  }

  async createBuyerAccount(payload) {
    if (this.providerId === 'phone') {
      payload['alert_block'] = 'Buyer Phone Auth';
      payload['phone'] = this.phoneNumber;
    }

    if (this.params && this.params.user_id) {
      payload['alert_user'] = true;
    }

    this.spinner.hide();
    const modal = await this.modalController.create({
      component: BuyerFormPage,
      cssClass: 'custom-modal',
      componentProps: {
        params: this.params,
        phone: this.phoneNumber,
        uid: this.uid
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    console.log('inputs data', data);
    // if (!buyerName) return;

    // payload['first_name'] = buyerName.first_name;
    // payload['last_name'] = buyerName.last_name;
    // payload['state'] = buyerName.state;
    // payload['city'] = buyerName.city;
    // payload['suburb'] = buyerName.suburb;
    // payload['houseNumber'] = buyerName.houseNumber;
    // payload['postalCode'] = buyerName.postalCode;

    // this.spinner.show();
    // this.commonServ.createBuyerFromFacebook(payload).then(() => {
    //   console.log('user buyer account created!');
    //   this.modalController.dismiss({
    //     data: payload
    //   });
    // }).finally(() => {
    //   this.spinner.hide();
    // });
  }

  async reloginSellerAccount(payload) {
    payload['alert_block'] = 'Ask Store Name';
    this.commonServ.reloginSellerFromFacebook(payload).then(() => {
      console.log('user seller account relogin!');
      this.spinner.hide();
      closeMess();
    });
  }

  async createSellerAccount(payload) {
    payload['alert_block'] = 'Ask Store Name';
    this.commonServ.createSellerFromFacebook(payload).then(() => {
      console.log('user seller account created!');
      closeMess();
    }).finally(() => {
      this.spinner.hide();
    });
  }

  async createJobberAccount(payload) {
    payload['alert_block'] = 'Ask Jobber Store Name';
    this.commonServ.createJobberFromFacebook(payload).then(() => {
      console.log('user jobber account created!');
      closeMess();
    }).finally(() => {
      this.spinner.hide();
    });
  }

  logout() {
    this.authServ.afAuth.signOut().then(() => {
      console.log('signed out');
    })
  }

  closeModal() {
    this.modalController.dismiss();
  }
}

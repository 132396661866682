import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoCompleteService } from 'ionic4-auto-complete';
import { AUAddress } from 'src/app/common/interfaces';
import { api } from 'src/environments/api';

@Injectable({
  providedIn: 'root'
})
export class SuburbsService implements AutoCompleteService {
  labelAttribute = 'name';

  private suburbs: Array<AUAddress> = [];

  constructor(private http: HttpClient) { }

  getResults(keyword?: string): Observable<any[]> {
    if (!keyword || keyword.length < 1) {
      return EMPTY;
    }
    keyword = typeof keyword === 'string' ? keyword : '';

    const url = `${api.here_geocode_url}?country=${api.here_country_code}&apiKey=${api.here_api_key}&query=${encodeURIComponent(keyword)}&maxResults=20`;

    console.log('url', url);

    let observable: Observable<any>;

    // if (this.suburbs.length === 0) {
    console.log('api.here_geocode_url', api);

    observable = this.http.get(url).pipe(
      map(
        (results) => {
          if (results['suggestions']) {
            console.log('this.results', results);
            this.suburbs = results['suggestions'];
          }
          console.log('this.suburbs', this.suburbs);

          return this.suburbs;
        }
      )
    );
    // } else {
    //   console.log('with suburbs', this.suburbs);
    //   observable = of(this.suburbs);
    // }

    return observable.pipe(
      map(
        (result) => {
          console.log('result', result);

          if (!result) return [];

          result.forEach(
            (item) => {
              const district = item.address.district ? item.address.district + ', ' : '';
              const state = item.address.state ? item.address.state + ' ' : '';
              const postalCode = item.address.postalCode ? item.address.postalCode : '';
              console.log('district', district);

              item['name'] = `${district}${state}${postalCode}`;
              console.log('item.name', item.name);
            }
          );
          return result;
        }
      )
    );
  }
}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { QueryParams, Address } from 'src/app/common/interfaces';
import { ModalController } from '@ionic/angular';

import { AutoCompleteOptions } from 'ionic4-auto-complete';
import { AddressesService } from 'src/app/services/addresses.service';
import { SuburbsService } from 'src/app/services/suburbs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AUAddressSuggestion } from 'src/app/common/models';
import { validatePhoneNumber } from 'src/app/validators/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Storage } from '@ionic/storage';

@UntilDestroy()
@Component({
  selector: 'app-buyer-form',
  templateUrl: './buyer-form.page.html',
  styleUrls: ['./buyer-form.page.scss'],
})
export class BuyerFormPage implements OnInit, AfterViewInit {

  @Input() uid: string;
  @Input() params: QueryParams;
  @Input() phone: string;

  suburbs: Array<[AUAddressSuggestion]>;

  public options: AutoCompleteOptions;
  public options2: AutoCompleteOptions;

  public address: string = '';
  public suburb: string = '';

  manualAddress: boolean;
  selectedAddress: Address;

  buyerForm: FormGroup;
  submitted: boolean;

  constructor(private commonServ: CommonService, private modalController: ModalController, public provider: AddressesService, public provider2: SuburbsService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private storage: Storage) {
    this.options = new AutoCompleteOptions();
    this.options2 = new AutoCompleteOptions();

    this.options.autocomplete = 'on';
    this.options.debounce = 300;
    this.options.placeholder = 'Type complete address...';
    this.options2.autocomplete = 'on';
    this.options2.debounce = 500;
    this.options2.placeholder = 'Type post code...';
    this.initForm();
  }

  ngOnInit() {
    if (!window.history.state.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
  }

  ngAfterViewInit() {
    console.log('uid', this.uid);
    console.log('params', this.params);
  }

  async initForm() {
    this.buyerForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone: ['', [Validators.required, validatePhoneNumber]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-].[a-zA-Z0-9-.]+$')]],
      address: ['', []],
      state: ['', [Validators.required]],
      suburb: ['', [Validators.required]],
      postalCode: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      houseNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      street: ['', [Validators.required]]
    });

    this.bf.first_name.setValue(await this.storage.get('first_name') || '');
    this.bf.last_name.setValue(await this.storage.get('last_name') || '');
    this.bf.phone.setValue(await this.storage.get('phone') || '');
    this.bf.email.setValue(await this.storage.get('email') || '');
    this.bf.state.setValue(await this.storage.get('state') || '');
    this.bf.suburb.setValue(await this.storage.get('suburb') || '');
    this.bf.postalCode.setValue(await this.storage.get('postalCode') || '');
    this.bf.houseNumber.setValue(await this.storage.get('houseNumber') || '');
    this.bf.street.setValue(await this.storage.get('street') || '');
  }

  get bf() { return this.buyerForm.controls; }

  inputManualAddress() {
    this.manualAddress = true;
    this.selectedAddress = null;
  }

  async getSuburbs() {
    this.bf.suburb.setValue(null);
    this.bf.state.setValue(null);
    if (!this.bf.houseNumber.value || !this.bf.street.value || !this.bf.postalCode.value) return;

    const keyword = `${this.bf.houseNumber.value} ${this.bf.street.value} ${this.bf.postalCode.value}`;
    this.provider2.getResults(keyword).pipe(untilDestroyed(this)).subscribe((suburbs) => {
      this.suburbs = suburbs;
      console.log(this.suburbs, 'suburbs');
    });
  }

  selectSuburb(suburb) {
    this.bf.suburb.setValue(suburb.address.district);
    this.bf.state.setValue(suburb.address.state);
    console.log('this.suburb', this.bf.suburb.value);
    this.suburbs = [];
  }

  async submit() {
    this.spinner.show();
    let payload = {
      uid: this.uid || null,
      user_id: (this.params && this.params.user_id) || null,
      phone: this.commonServ.formatPhoneNumber(this.bf.phone.value),
      email: this.bf.email.value
    };
    
    const profile = {
      first_name: this.bf.first_name.value,
      last_name: this.bf.last_name.value,
      phone: this.commonServ.formatPhoneNumber(this.bf.phone.value),
      email: this.bf.email.value,
      houseNumber: this.bf.houseNumber.value,
      street: this.bf.street.value,
      suburb: this.bf.suburb.value,
      state: this.bf.state.value,
      postalCode: this.bf.postalCode.value
    };

    payload = { ...payload, ...profile };

    console.log('facebook payload', payload);
    // return;
    await this.commonServ.createBuyerFromFacebook(payload);
    this.spinner.hide();
    // this.removeTempForm();
    this.modalController.dismiss(profile);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  on(output, event): void {
    console.log(output, event);
    if (!event) return;

    if (output === 'itemSelected') {
      this.selectedAddress = event.address;
      this.bf.houseNumber.setValue(this.selectedAddress.houseNumber || '');
      this.bf.street.setValue(this.selectedAddress.street || '');
      this.bf.suburb.setValue(this.selectedAddress.district);
      this.bf.state.setValue(this.selectedAddress.state);
      this.bf.postalCode.setValue(this.selectedAddress.postalCode);
    }
  }

  saveTempForm() {
    this.commonServ.setStorage('first_name', this.bf.first_name.value);
    this.commonServ.setStorage('last_name', this.bf.last_name.value);
    this.commonServ.setStorage('phone', this.bf.phone.value);
    this.commonServ.setStorage('email', this.bf.email.value);
    this.commonServ.setStorage('houseNumber', this.bf.houseNumber.value);
    this.commonServ.setStorage('street', this.bf.street.value);
    this.commonServ.setStorage('suburb', this.bf.suburb.value);
    this.commonServ.setStorage('state', this.bf.state.value);
    this.commonServ.setStorage('postalCode', this.bf.postalCode.value);
  }

  removeTempForm() {
    this.commonServ.removeStorage('last_name');
    this.commonServ.removeStorage('first_name');
    this.commonServ.removeStorage('phone');
    this.commonServ.removeStorage('email');
    this.commonServ.removeStorage('houseNumber');
    this.commonServ.removeStorage('street');
    this.commonServ.removeStorage('suburb');
    this.commonServ.removeStorage('state');
    this.commonServ.removeStorage('postalCode');
  }
}

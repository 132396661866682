import { FormControl, ValidationErrors } from '@angular/forms';
import { Injector } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

export const validatePhoneNumber = (control: FormControl): ValidationErrors => {
  console.log('control', control.value);
  let injector = Injector.create({ providers: [{ provide: CommonService, useClass: CommonService, deps: [] }] });
  let service = injector.get(CommonService);
  const isValid = service.isValidNumberForRegion(control.value);
  console.log('isValid', isValid);

  return !isValid ? {
    'invalid': true
  } : null;
}
